import React from 'react';
import {
  Table,
  Form,
  Button,
  Input,
  message,
  Drawer,
  Space,
  DatePicker,
  Row,
  Col,
  Popconfirm,
  Modal,
  Select,
  InputNumber
} from 'antd';
import {
  SearchOutlined,
  FileAddOutlined
} from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/zh_CN';

import * as PathUrl from '@/common/PathUrl'
import ProjectTestee from './components/ProjectTestee'
import ProjectUser from './components/ProjectUser'
import { axiosGet, axiosDelete, axiosPostBody, axiosPut } from '@/utils/Requests'
import { connect } from 'react-redux';
import { actions } from '@/redux/modules/project';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 5, span: 8 },
};

class Project extends React.Component {

  formRef = React.createRef();

  constructor(props) {
    super(props)
    this.columns = [
      {
        title: '项目编号',
        dataIndex: 'projectIdStr',
        sorter: false,
      },
      {
        title: '项目名称',
        dataIndex: 'projectName',
      },
      {
        title: '期数',
        dataIndex: 'phase',
      },
      {
        title: '计划数',
        dataIndex: 'planCount',
      },
      {
        title: '实际数',
        dataIndex: 'actualCount',
      },
      {
        title: '开始日期',
        dataIndex: 'startDate',
      },
      {
        title: '结束日期',
        dataIndex: 'endDate',
      },
      {
        title: '创建日期',
        dataIndex: 'createAt',
      },
      {
        title: '操作',
        key: 'action',
        render: (_text, record) => (
          <Space>
            <Popconfirm title="确认删除?" onConfirm={() => this.deleteRecord(record)}>
              <Button type="link" size="small">删除</Button>
            </Popconfirm>

            <Button type="link" size="small" onClick={() => this.modifyRecord(record)}>
              编辑
            </Button>

            <Button type="link" size="small" onClick={() => this.viewUser(record)}>查看操作员</Button>
            <Button type="link" size="small" onClick={() => this.viewTestee(record)}>被试人员</Button>
            <Button type="link" size="small" onClick={() => this.viewScan(record)}>检测信息</Button>
          </Space>
        ),
      },
    ];

    var username = localStorage.getItem("username");
    if (null == username) {
      message.error("请登录！");
    }
    this.state = {
      addVisible: false,
      updateVisible: false,
      detailsVisible: false,
      projectUserVisible: false,
      data: [],
      pagination: {
        pageNum: 1,
        pageSize: 10,
      },
      loading: false,
      searchText: {
      },
      record: {},
    };
  };

  componentDidMount() {
    let type = localStorage.type
    this.setState({
      userType: type,
    })
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  handleTableChange = (pagination, filters, sorter) => {
    pagination.pageNum = pagination.current;
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  }


  detailsDrawerOnClose = () => {
    this.setState({
      detailsVisible: false,
    });
  };

  projectUserDrawerOnClose = () => {
    this.setState({
      projectUserVisible: false,
    });
  }


  fetch = (params = {}) => {
    this.setState({ loading: true });
    let data = {
      sortField: params.sortField,
      sortOrder: params.sortOrder,
      pageNum: params.pagination.pageNum,
      pageSize: params.pagination.pageSize,
      ...this.state.searchText
    };
    axiosGet(PathUrl.PROJECT_URL, data)
      .then(response => {
        this.setState({
          loading: false,
          data: response.data.list,
          pagination: {
            total: response.data.total,
            pageNum: params.pagination.pageNum,
            pageSize: params.pagination.pageSize,
          },
        });
      });
  }

  //search
  search = (values) => {
    let startDate = null;
    let endDate = null;
    if (null != values.date) {
      startDate = values.date[0].locale('zh-cn').format('YYYY-MM-DD');
      endDate = values.date[1].locale('zh-cn').format('YYYY-MM-DD');
    }
    this.setState({
      searchText: {
        ...values,
        startDate: startDate,
        endDate: endDate
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
      },
    });
    this.fetch({ pagination: this.state.pagination });
  };

  viewTestee = (record) => {
    this.setState({
      detailsVisible: true,
      record: record
    });

    this.props.setProject(record.projectIdStr)
  }

  viewUser = (record) => {
    this.setState({
      projectUserVisible: true,
      record: record
    });

    this.props.setProject(record.projectIdStr)
  }

  viewScan = (record) => {
    this.props.history.push({pathname:'/scan',query:{projectName: record.projectName, phase: record.phase}});
  }

  deleteRecord = (record) => {
    axiosDelete(PathUrl.PROJECT_URL + "/" + record.projectIdStr, {})
      .then(_r => {
        message.success("删除成功！");
        this.fetch({ pagination: this.state.pagination });
      })
  }

  handleAddClick = () => {
    this.setState({
      addVisible: true,
    });
  }
  handleAddCancel = () => {
    this.setState({
      addVisible: false,
    });
  }

  addProject = (values) => {
    let startDate = null;
    let endDate = null;
    if (null != values.date) {
      startDate = values.date[0].locale('zh-cn').format('YYYY-MM-DD');
      endDate = values.date[1].locale('zh-cn').format('YYYY-MM-DD');
    }
    let data = {
      ...values,
      startDate: startDate,
      endDate: endDate
    };
    axiosPostBody(PathUrl.PROJECT_URL, data)
      .then(_response => {
        message.success("保存成功！");
        this.fetch({ pagination: this.state.pagination });
        this.setState({
          addVisible: false,
        });
      });
  }


  handleUpdateCancel = () => {
    this.setState({
      updateVisible: false,
    });
  }

  modifyRecord(record) {
    let date = null;
    if (null != record.startDate && "" !== record.startDate && null != record.endDate && "" !== record.endDate) {
      date = [moment(record.startDate), moment(record.endDate)]
    }
    this.setState({
      updateVisible: true,
    }, () => {
      this.formRef.current.setFieldsValue({
        ...record,
        date: date,
      });
    });

  }

  updateProject = (values) => {
    let startDate = null;
    let endDate = null;
    if (null != values.date) {
      startDate = values.date[0].locale('zh-cn').format('YYYY-MM-DD');
      endDate = values.date[1].locale('zh-cn').format('YYYY-MM-DD');
    }
    let data = {
      ...values,
      startDate: startDate,
      endDate: endDate
    };
    axiosPut(PathUrl.PROJECT_URL, data)
      .then(_response => {
        message.success("更新成功！");
        this.fetch({ pagination: this.state.pagination });
        this.setState({
          updateVisible: false,
        });
      });
  }

  render() {
    const { data, pagination, loading } = this.state;
    const paginationProps = {
      ...pagination,
      showSizeChanger: true,
      showQuickJumper: true,
      pageSize: pagination.pageSize,
      current: pagination.pageNum,
      showTotal: ((total) => {
        return `总共 ${total} 条`;
      }),
    };
    return (
      <div>
        <Drawer
          width={900}
          placement="right"
          closable={true}
          onClose={this.detailsDrawerOnClose}
          visible={this.state.detailsVisible}
        >
          <ProjectTestee />
        </Drawer>

        <Drawer
          width={900}
          placement="right"
          closable={true}
          onClose={this.projectUserDrawerOnClose}
          visible={this.state.projectUserVisible}
        >
          <ProjectUser />
        </Drawer>

        <Button
          type="primary"
          onClick={() => this.handleAddClick()}
          // style={{ marginBottom: 15, marginLeft: 15, display: this.state.userType === UserType.SUPER_ADMIN.toString() ? 'block' : 'none' }}
          style={{ marginBottom: 15, marginLeft: 15 }}
          icon={<FileAddOutlined />}
        >
          添加项目
        </Button>

        <Form
          name='search'
          onFinish={(values) => this.search(values)}
        >
          <Row gutter={[18, 0]} style={{ marginLeft: 7 }}>
            <Col span={6} xs={24} sm={12} md={12} lg={6} xl={6}>
              <Form.Item
                label="项目编号"
                name="projectId"
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={6} xs={24} sm={12} md={12} lg={6} xl={6}>
              <Form.Item
                label="项目名称"
                name="projectName"
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={6} xs={24} sm={12} md={12} lg={6} xl={6}>
              <Form.Item
                label="创建日期"
                name="date"
              >
                <RangePicker locale={locale} allowClear />
              </Form.Item>
            </Col>

            <Col span={6} xs={24} sm={12} md={12} lg={6} xl={6}>
              <Form.Item>
                <Button type="primary" icon={<SearchOutlined />} htmlType="submit">
                  搜索
                </Button>
              </Form.Item>
            </Col>
          </Row>

        </Form>

        <Table
          columns={this.columns}
          rowKey={record => record.projectIdStr}
          dataSource={data}
          pagination={paginationProps}
          loading={loading}
          onChange={this.handleTableChange}
          size='middle'
          scroll={{ x: 1300 }}
        />


        <Modal
          title="添加"
          footer={null}
          visible={this.state.addVisible}
          confirmLoading={this.state.confirmLoading}
          onCancel={this.handleAddCancel}
        >
          <Form
            {...layout}
            onFinish={this.addProject}
          >

            <Form.Item
              label="项目名称"
              name="projectName"
              rules={[{ required: true, message: '请填写!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="项目期数"
              name="phase"
              rules={[{ required: true, message: '请填写!' }]}
            >
              <Select placeholder="请选择" allowClear>
                <Option value="一期">一期</Option>
                <Option value="二期">二期</Option>
                <Option value="三期">三期</Option>
                <Option value="四期">四期</Option>
                <Option value="五期">五期</Option>
                <Option value="六期">六期</Option>
                <Option value="七期">七期</Option>
                <Option value="八期">八期</Option>
                <Option value="九期">九期</Option>
                <Option value="十期">十期</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="计划被试总数"
              name="planCount"
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
              label="实际被试总数"
              name="actualCount"
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
              label="计划日期"
              name="date"
            >
              <RangePicker locale={locale} allowClear />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                添加
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="更新"
          footer={null}
          visible={this.state.updateVisible}
          onCancel={this.handleUpdateCancel}
        >
          <Form
            {...layout}
            onFinish={this.updateProject}
            ref={this.formRef}
          >
            <Form.Item
              label="项目编号"
              name="projectIdStr"
              rules={[{ required: true, message: '请填写!' }]}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              label="项目名称"
              name="projectName"
              rules={[{ required: true, message: '请填写!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="项目期数"
              name="phase"
              rules={[{ required: true, message: '请填写!' }]}
            >
              <Select placeholder="请选择" allowClear>
                <Option value="一期">一期</Option>
                <Option value="二期">二期</Option>
                <Option value="三期">三期</Option>
                <Option value="四期">四期</Option>
                <Option value="五期">五期</Option>
                <Option value="六期">六期</Option>
                <Option value="七期">七期</Option>
                <Option value="八期">八期</Option>
                <Option value="九期">九期</Option>
                <Option value="十期">十期</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="计划被试总数"
              name="planCount"
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
              label="实际被试总数"
              name="actualCount"
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
              label="计划日期"
              name="date"
            >
              <RangePicker locale={locale} allowClear />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                更新
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    projectId: state.projectReducer.projectId,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setProject: (projectId) => dispatch(actions.setProject(projectId)),
  }
}

Project = connect(mapStateToProps, mapDispatchToProps)(Project)

export default Project


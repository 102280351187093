import React from 'react';
import Main from './Main'
import ScanItem from '@/scan/ScanItem'
import Project from '@/project/Project'
import UploadUserFile from '@/user/UploadUserFile'
import AdminUser from '@/admin/User'
import Password from '@/password/Password'
import * as UserType from '@/common/UserType'
import * as PathUrl from '@/common/PathUrl'
import { axiosPut, axiosGet } from '../utils/Requests'
import { Tooltip, Modal } from 'antd';

import {
  HomeOutlined,
  PoweroffOutlined,
  UserOutlined,
  ProfileOutlined,
  TeamOutlined,
  ProjectOutlined,
  UnlockOutlined
} from '@ant-design/icons';
import { Route, Link } from 'react-router-dom';
import { Layout, Menu, Breadcrumb, Avatar, message, Form, Input, Button } from 'antd';


const { Header, Content, Footer, Sider } = Layout;

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 13 },
};
const tailLayout = {
  wrapperCol: { offset: 7, span: 8 },
};


export default class Frame extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: false,
      navText: "",
      showElem: false,
      showSuperElem: false,
      current: '/',
      openKeys: [],
      isPasswordModalVisible: false,
    };

  }

  componentDidMount() {
    this.checkDefaultPassword();

    let token = localStorage.getItem("token");
    if (null == token) {
      message.error("请登录！");
      this.props.history.push('/login');
    }

    let type = localStorage.type
    if (type === UserType.ADMIN.toString()) {
      this.setState({
        showElem: true,
      })
    }
    if (type === UserType.SUPER_ADMIN.toString()) {
      this.setState({
        showElem: true,
        showSuperElem: true,
      })
    }
  }

  // change default password when first login
  checkDefaultPassword = () => {
    axiosGet(PathUrl.USER_PASSWORD_DEFAULT_CHECK_URL, {})
      .then(response => {
        if (response.data) {
          message.warn("请修改默认密码");
          this.props.history.push('/password');
        }
      });
  }

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  changeNavText(text) {
    this.checkDefaultPassword();
    this.setState({ navText: text })
  };

  menuClick = e => {
    this.setState({
      current: e.key,
    })
  }

  onOpenChange = e => {
    this.setState({
      openKeys: e
    })
  }

  exit = () => {
    localStorage.type = null;
    localStorage.token = null;
    localStorage.username = null;
    localStorage.removeItem("username");
    localStorage.removeItem("type");
    localStorage.removeItem("token");
    localStorage.clear();
    message.success("成功");
    this.props.history.push('/login');
  };

  changePassword = () => {
    this.setState({
      isPasswordModalVisible: true,
    })
  }

  handleCancel = () => {
    this.setState({
      isPasswordModalVisible: false,
    })
  }

  updatePassword = (values) => {
    if (null == values || values.newPassword !== values.newPassword2) {
      message.error("新密码两次不一致");
      return;
    }

    axiosPut(PathUrl.USER_PASSWORD_URL, values)
      .then(_response => {
        message.success("保存成功！");
        this.setState({
          isPasswordModalVisible: false,
        });

        this.exit();
      });
  }

  render() {
    const { collapsed, picture } = this.state;
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={this.onCollapse}
          width="160"
        >
          <div className="logo">
            <img alt='' width='30px' height='30px' src='logo192.png' />
            <span style={{
              textAlign: 'center',
              marginLeft: '30px',
              marginTop: '-25px',
              color: 'white',
              display: collapsed ? 'none' : 'block',
              fontSize: 13
            }}>
              临床皮肤检测
            </span>
          </div>
          <Menu theme="dark" onClick={this.menuClick} defaultSelectedKeys={[this.state.current]} selectedKeys={[this.state.current]} defaultOpenKeys={this.state.openKeys} openKeys={this.state.openKeys} mode="inline" onOpenChange={this.onOpenChange}>
            <Menu.Item key="/main" icon={<HomeOutlined />} onClick={() => this.changeNavText('首页')}>
              <Link to="/main">首页</Link>
            </Menu.Item>

            <Menu.Item key="/scan" style={{ display: this.state.showElem ? 'block' : 'none' }}
              icon={<ProfileOutlined />} onClick={() => this.changeNavText('检测信息')}>
              <Link to="/scan">检测信息</Link>
            </Menu.Item>

            <Menu.Item key="/project" style={{ display: this.state.showElem ? 'block' : 'none' }}
              icon={<ProjectOutlined />} onClick={() => this.changeNavText('项目信息')}>
              <Link to="/project">项目信息</Link>
            </Menu.Item>

            <Menu.Item key="/admin-user" style={{ display: this.state.showSuperElem ? 'block' : 'none' }}
              icon={<TeamOutlined />} onClick={() => this.changeNavText('人员管理')}>
              <Link to="/admin-user">人员管理</Link>
            </Menu.Item>

            <Menu.Item key="change-password" onClick={this.changePassword} icon={<UnlockOutlined />}>
              修改密码
            </Menu.Item>

            <Menu.Item key="9" onClick={this.exit} icon={<PoweroffOutlined />}>
              退出登录
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>

            <Tooltip placement="bottom" title={localStorage.username}>
              <Avatar src={picture} style={{ backgroundColor: '#87d068', marginLeft: '95%' }}
                icon={<UserOutlined />} />
            </Tooltip>
          </Header>
          <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item >{this.state.navText}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
              <Route path="/main" component={Main} />
              <Route path="/scan" component={ScanItem} />
              <Route path="/upload-user-file" component={UploadUserFile} />
              <Route path="/admin-user" component={AdminUser} />
              <Route path="/project" component={Project} />
              <Route path="/password" component={Password} />
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}> ©2022</Footer>
        </Layout>

        <Modal title="修改密码" visible={this.state.isPasswordModalVisible} footer={null} onCancel={this.handleCancel}>
          <Form
            {...layout}
            name="modify"
            onFinish={this.updatePassword}
          >
            <Form.Item
              label="旧密码"
              name="oldPassword"
              rules={[{ required: true, message: '请填写!' }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="新密码"
              name="newPassword"
              rules={[{ required: true, message: '请填写!' }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="确认新密码"
              name="newPassword2"
              rules={[{ required: true, message: '请填写!' }]}
            >
              <Input.Password />
            </Form.Item>


            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                修改
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Layout>
    );

  }
}
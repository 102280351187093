import { Carousel } from 'antd';

function Main() {
    return (
        <div className="App">
            <Carousel autoplay>
                <div>
                    <img alt="" src="banner.png" />
                </div>
                <div>
                    <img alt="" src="banner.png" />
                </div>
                <div>
                    <img alt="" src="banner.png" />
                </div>
            </Carousel>
            <h2 style={{marginTop: 10}}>
                成都可恩生物科技有限公司
            </h2>
            <p>
                成都可恩生物科技有限公司，成立于2018年3月，由一群具备丰富科技成果转化，及产业化经验的专家创办。公司已获得康华生物，泰格股权投资等国内多家机构的投资。可恩生物聚焦于生物医药领域，主要从事结核诊断生物制品、治疗类疫苗的研发及产业化。目前在成都设有研发基地及产业化基地。
            </p>
            <p>
                公司在成都高新西区拥有超过2000平方米的研发小试实验室、中试实验室、动物实验室。拥有各类细菌生产工艺开发、大分子质量研究，动物实验等研究人员。同时公司位于成都天府国际生物城的6000平方米的GMP产业化基地已完成建筑交付，目前正在装修建设当中。
            </p>
        </div>
    );
}

export default Main;

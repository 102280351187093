import ReactDOM from 'react-dom';
import './index.css';
import Home from './main/Home'
import Login from './user/Login'
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/modules/index'

import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';
import { ConfigProvider } from 'antd';

ReactDOM.render(
    <ConfigProvider locale={locale}>
        <Provider store={store}>
            <BrowserRouter>
                <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/" component={Home} />
                </Switch>
            </BrowserRouter>
        </Provider>
    </ConfigProvider>
    ,
    document.getElementById('root')
);


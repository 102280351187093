import React from 'react';
import {
  Table,
  Form,
  Button,
  Input,
  message,
  Row,
  Col,
  // Space,
  // Popconfirm
} from 'antd';
import {
  SearchOutlined,
} from '@ant-design/icons';

import * as PathUrl from '@/common/PathUrl'
import { axiosGet, axiosDelete } from '@/utils/Requests'
import { connect } from 'react-redux';
import { actions } from '@/redux/modules/project'
import AddProjectTestee from './AddProjectTestee';


class ProjectTestee extends React.Component {

  formRef = React.createRef();

  constructor(props) {
    super(props)
    this.columns = [
      {
        title: '受试者编号',
        dataIndex: 'entryGroupNum',
        sorter: false,
      },
      {
        title: '受试者姓名',
        dataIndex: 'name',
      },
      // {
      //   title: '电话',
      //   dataIndex: 'phone',
      // },
      // {
      //   title: '身份证号',
      //   dataIndex: 'idCard',
      // },
      // {
      //   title: '性别',
      //   dataIndex: 'gender',
      //   render: (_text, record) => {
      //     if (record.gender === 1) {
      //       return (
      //         "男"
      //       );
      //     } else if (record.gender === 2) {
      //       return (
      //         "女"
      //       );
      //     } else {
      //       return (
      //         "未知"
      //       );
      //     }
      //   },
      // },
      // {
      //   title: '年龄',
      //   dataIndex: 'age',
      // },
      {
        title: '创建日期',
        dataIndex: 'createAt',
      },
      // {
      //   title: '操作',
      //   key: 'action',
      //   render: (_text, record) => (
      //     <Space>
      //       <Popconfirm title="确认删除?" onConfirm={() => this.deleteRecord(record)}>
      //         <Button type="link" size="small">删除</Button>
      //       </Popconfirm>
      //     </Space>
      //   ),
      // },
    ];

    var username = localStorage.getItem("username");
    if (null == username) {
      message.error("请登录！");
    }
    this.state = {
      projectId: 0,
      data: [],
      pagination: {
        pageNum: 1,
        pageSize: 10,
      },
      loading: false,
      searchText: {
      },
      isRefechProjectTestee: false,
    };
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  static getDerivedStateFromProps(nextProps, preState) {
    if (nextProps.projectId !== preState.projectId || nextProps.isRefechProjectTestee !== preState.isRefechProjectTestee) {
      return {
        ...preState,
        projectId: nextProps.projectId,
        isRefechProjectTestee: nextProps.isRefechProjectTestee,
      }
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.projectId !== this.state.projectId || prevProps.isRefechProjectTestee !== this.state.isRefechProjectTestee) {
      const { pagination } = this.state;
      this.fetch({ pagination });

      // 重新获取后重置条件
      this.setState({
        isRefechProjectTestee: false,
      })
      this.props.refetchProjectTestee(false);
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    pagination.pageNum = pagination.current;
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  }

  fetch = (params = {}) => {
    this.setState({ loading: true });
    let data = {
      sortField: params.sortField,
      sortOrder: params.sortOrder,
      pageNum: params.pagination.pageNum,
      pageSize: params.pagination.pageSize,
      projectId: this.state.projectId,
      ...this.state.searchText
    };
    axiosGet(PathUrl.PROJECT_TESTEE_URL, data)
      .then(response => {
        this.setState({
          loading: false,
          data: response.data.list,
          pagination: {
            total: response.data.total,
            pageNum: params.pagination.pageNum,
            pageSize: params.pagination.pageSize,
          },
        });
      });
  }

  //search
  search = (values) => {
    let startDate = null;
    let endDate = null;
    if (null != values.date) {
      startDate = values.date[0].locale('zh-cn').format('YYYY-MM-DD');
      endDate = values.date[1].locale('zh-cn').format('YYYY-MM-DD');
    }
    this.setState({
      searchText: {
        ...values,
        startDate: startDate,
        endDate: endDate
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
      },
    });
    this.fetch({ pagination: this.state.pagination });
  };

  deleteRecord = (record) => {
    axiosDelete(PathUrl.PROJECT_TESTEE_URL + "/" + record.id, {})
      .then(_r => {
        message.success("删除成功！");
        this.fetch({ pagination: this.state.pagination });
      })
  }

  setAddProjectTesteeVisiable = () => {
    this.props.setAddProjectTesteeVisiable(true);
  }

  render() {
    const { data, pagination, loading } = this.state;
    const paginationProps = {
      ...pagination,
      showSizeChanger: true,
      showQuickJumper: true,
      pageSize: pagination.pageSize,
      current: pagination.pageNum,
      showTotal: ((total) => {
        return `总共 ${total} 条`;
      }),
    };
    return (
      <div>
        {/* <Button type="primary" onClick={() => this.setAddProjectTesteeVisiable()} style={{ marginBottom: 15, marginLeft: 15 }}>添加被试人员</Button> */}
        <AddProjectTestee />
        <Form
          name='search'
          onFinish={(values) => this.search(values)}
        >
          <Row gutter={[18, 0]} style={{ marginLeft: 7 }}>
            {/* <Col span={8}>
              <Form.Item
                label="被试编号"
                name="id"
              >
                <Input allowClear />
              </Form.Item>
            </Col> */}
            <Col span={8}>
              <Form.Item
                label="受试者姓名"
                name="name"
              >
                <Input allowClear />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item>
                <Button type="primary" icon={<SearchOutlined />} htmlType="submit">
                  搜索
                </Button>
              </Form.Item>
            </Col>
          </Row>

        </Form>

        <Table
          columns={this.columns}
          rowKey={record => record.id}
          dataSource={data}
          pagination={paginationProps}
          loading={loading}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    projectId: state.projectReducer.projectId,
    isRefechProjectTestee: state.projectReducer.isRefechProjectTestee,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setProject: (projectId) => dispatch(actions.setProject(projectId)),
    setAddProjectTesteeVisiable: (addVisiable) => dispatch(actions.setAddProjectTesteeVisiable(addVisiable)),
    refetchProjectTestee: (isRefechProjectTestee) => dispatch(actions.refetchProjectTestee(isRefechProjectTestee)),
  }
}

ProjectTestee = connect(mapStateToProps, mapDispatchToProps)(ProjectTestee)

export default ProjectTestee


import React from 'react';
import { Form, Input, Button, message } from 'antd';
import * as PathUrl from '@/common/PathUrl'
import { axiosPostBody } from '@/utils/Requests';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};


class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            username: '',
            password: ''
        }

    }

    componentDidMount() {
        let token = localStorage.getItem("token")

        if (null != token) {
            this.jumpToMain()
            return;
        }
    }

    jumpToMain = () => {
        this.props.history.push('/main');
    }

    onFinish = (values) => {
        let data = {
            ...values,
        }
        axiosPostBody(PathUrl.LOGIN_URL, data)
            .then(response => {
                message.success("登录成功！");
                localStorage.setItem("token", response.data.token)
                localStorage.setItem("username", response.data.username)
                localStorage.setItem("type", parseInt(response.data.type))
                this.jumpToMain()
            });
    }

    render() {

        return (
            <div>
                <Form
                    {...layout}
                    name="basic"
                    onFinish={this.onFinish}
                    style={{ paddingTop: '10%' }}
                >
                    <Form.Item
                        label="用户名"
                        name="username"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input value={this.state.username} />
                    </Form.Item>

                    <Form.Item
                        label="密码"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            登录
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

export default Login;
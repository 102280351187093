import React from 'react';
import {
    Form,
    Button,
    Input,
    message,
} from 'antd';

import * as PathUrl from '@/common/PathUrl'
import { axiosPut } from '@/utils/Requests'

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 8 },
};

export default class Password extends React.Component {

    formRef = React.createRef();

    constructor(props) {
        super(props)

        var username = localStorage.getItem("username");
        if (null == username) {
            message.error("请登录！");
        }
        this.state = {

        };
    }

    componentDidMount() {
    }

    updatePassword = (values) => {
        if (null == values || values.newPassword !== values.newPassword2) {
            message.error("新密码两次不一致");
            return;
        }

        axiosPut(PathUrl.USER_PASSWORD_URL, values)
            .then(_response => {
                message.success("保存成功！");
                this.setState({
                    isPasswordModalVisible: false,
                });

                this.exit();
            });
    }

    exit = () => {
        localStorage.type = null;
        localStorage.token = null;
        localStorage.username = null;
        localStorage.removeItem("username");
        localStorage.removeItem("type");
        localStorage.removeItem("token");
        localStorage.clear();
        message.success("成功");
        this.props.history.push('/login');
    };


    render() {

        return (
            <div>

                <Form
                    {...layout}
                    name="modify"
                    onFinish={this.updatePassword}
                >
                    <Form.Item
                        label="旧密码"
                        name="oldPassword"
                        rules={[{ required: true, message: '请填写!' }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="新密码"
                        name="newPassword"
                        rules={[{ required: true, message: '请填写!' }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="确认新密码"
                        name="newPassword2"
                        rules={[{ required: true, message: '请填写!' }]}
                    >
                        <Input.Password />
                    </Form.Item>


                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            修改
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}


import React from 'react';
import { Upload, message, Table } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import * as Params from '@/common/Params'
import * as PathUrl from '@/common/PathUrl'
import { CSVLink } from "react-csv";

const { Dragger } = Upload;

export default class UploadUserFile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            uploadMsgDataArr: [],
            uploadMsgData: [],
        }

        this.msgColumns = [
            {
                title: '用户名',
                dataIndex: 'username',
            },
            {
                title: '错误信息',
                dataIndex: 'msg',
            },
        ];
    }

    uploadFile = (info) => {
        const { status } = info.file;
        if (status !== 'uploading') {
        }
        if (status === 'done') {
            if (info.file.response.code === 0) {
                message.success('上传成功！');
                let data = info.file.response.data;
                if (data.length > 0) {
                    this.setState({
                        uploadMsgData: data
                    });
                    let msgDatas = [['行号', '用户名', '错误信息']];
                    for (let i = 0; i < data.length; i++) {
                        let arr = [data[i].index + 1, data[i].username, data[i].msg];
                        msgDatas.push(arr)
                    }
                    this.setState({
                        uploadMsgDataArr: msgDatas
                    })
                } else {
                    this.setState({
                        uploadMsgDataArr: [['行号', '用户名', '错误信息']],
                        uploadMsgData: []
                    })
                }
            } else {
                message.error(info.file.response.msg);
            }
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    render() {
        const props = {
            maxCount: 1,
            showUploadList: false,
            name: 'file',
            action: PathUrl.WEB_USERS_URL,
            data: {
            },
            headers: {
                "Authorization": Params.TOKEN_PREFIX + localStorage.token
            },
        };
        return (
            <div>
                下载模板：
                <a href='../user.xlsx'>下载</a>
                <br />
                <br />
                <Dragger {...props} onChange={this.uploadFile}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">点击或者拖文件上传</p>
                    <p className="ant-upload-hint">
                        上传用户列表文件
                    </p>
                </Dragger>

                <Table
                    columns={this.msgColumns}
                    rowKey={record => record.index}
                    dataSource={this.state.uploadMsgData}
                    style={{ marginTop: '20px' }}
                />

                <CSVLink data={this.state.uploadMsgDataArr}>下载错误信息</CSVLink>
            </div>
        );
    }
}

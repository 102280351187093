export const API_VERSION = "/scan/api/v1/";
export const WEB_API_VERSION = "/scan/web/api/v1/";

let env = process.env.NODE_ENV
let HOST = "http://127.0.0.1:8080"
if (env === 'development') {
    HOST = "https://scan.coentst.cn";
} else if (env === 'production') {
    HOST = "https://scan.coentst.cn";
}

export const LOGIN_URL = HOST + API_VERSION + 'login';
export const TOKEN_REFRESH_URL = HOST + API_VERSION + 'token/refresh';

export const USERS_URL = HOST + API_VERSION + 'users';

export const USER_URL = HOST + WEB_API_VERSION + 'user/';
export const USER_PASSWORD_URL = HOST + WEB_API_VERSION + 'user/password';
export const USER_PASSWORD_RESET_URL = HOST + WEB_API_VERSION + 'user/reset-password';
export const USER_PASSWORD_DEFAULT_CHECK_URL = HOST + API_VERSION + 'need-change-password';
export const USER_REGISTER_URL = HOST + WEB_API_VERSION + 'register';
export const USER_SEARCH_URL = HOST + WEB_API_VERSION + 'user/search';

export const SCANS_URL = HOST + WEB_API_VERSION + 'scans';
export const SCANS_EXPORT_URL = HOST + WEB_API_VERSION + 'scans-export';
export const SCAN_TESTEE_URL = HOST + WEB_API_VERSION + 'origin-testee';

export const WEB_USERS_URL = HOST + WEB_API_VERSION + 'users';

export const PROJECT_URL = HOST + WEB_API_VERSION + 'project';
export const PROJECT_TESTEE_URL = HOST + WEB_API_VERSION + 'project/testee';
export const PROJECT_USER_URL = HOST + WEB_API_VERSION + 'project/user';
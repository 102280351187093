
const initialState = {
    projectId: 0,
    addVisible: false,
    isRefechProjectTestee: false,
}

export const types = {
    SET_PROJECT: 'PROJECT/SET',
    SET_ADD_PROJECT_TESTEE_VISIABLE: 'setAddProjectTesteeVisiable',
    SET_REFETCH_PROJECT_TESTEE: 'refetchProjectTestee',
}

export const actions = {
    setProject: (projectId) => ({
        type: types.SET_PROJECT,
        projectId: projectId
    }),
    setAddProjectTesteeVisiable: (addVisible) => {
        return {
            type: types.SET_ADD_PROJECT_TESTEE_VISIABLE,
            addVisible: addVisible,
        };
    },
    refetchProjectTestee: (isRefechProjectTestee) => {
        return {
            type: types.SET_REFETCH_PROJECT_TESTEE,
            isRefechProjectTestee: isRefechProjectTestee,
        };
    }
}

const projectReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_PROJECT:
            return { ...state, projectId: action.projectId }
        case types.SET_ADD_PROJECT_TESTEE_VISIABLE:
            return { ...state, addVisible: action.addVisible }
        case types.SET_REFETCH_PROJECT_TESTEE:
            return { ...state, isRefechProjectTestee: action.isRefechProjectTestee }
        default:
            return state
    }
}

export default projectReducer
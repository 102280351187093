import React from 'react';
import {
    Table,
    Form,
    Button,
    Modal,
    Input,
    Select,
    Space,
    message,
    Tag,
    Row,
    Col,
    Popconfirm
} from 'antd';
import {
    SearchOutlined,
    UserAddOutlined,
    UsergroupAddOutlined,
} from '@ant-design/icons';

import * as PathUrl from '@/common/PathUrl'
import * as UserType from '@/common/UserType'
import { axiosDelete, axiosGet, axiosPostBody, axiosPut } from '@/utils/Requests'

const { Option } = Select;

const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 5, span: 8 },
};

export default class User extends React.Component {

    formRef = React.createRef();

    constructor(props) {
        super(props)
        this.columns = [
            {
                title: '用户编号',
                dataIndex: 'userId',
            },
            {
                title: '用户名',
                dataIndex: 'username',
            },
            {
                title: '姓名',
                dataIndex: 'name',
            },
            {
                title: '邮箱',
                dataIndex: 'email',
            },
            {
                title: '医院',
                dataIndex: 'hospital',
            },
            {
                title: '创建时间',
                dataIndex: 'createAt',
            },
            {
                title: '用户类型',
                dataIndex: 'type',
                render: (_text, record) => {
                    if (record.type === UserType.SUPER_ADMIN) {
                        return (
                            <Tag color='green'>
                                超级管理员
                            </Tag>
                        );
                    } else if (record.type === UserType.ADMIN) {
                        return (
                            <Tag color='volcano'>
                                项目管理员
                            </Tag>
                        );
                    } else if (record.type === UserType.DOCTER) {
                        return (
                            <Tag color='cyan'>
                                操作员
                            </Tag>
                        );
                    }
                },
            },
            {
                title: '操作',
                key: 'action',
                render: (_text, record) => (
                    <Space>
                        <Button type="link" size="small" onClick={() => this.modifyRecord(record)}>
                            编辑
                        </Button>

                        <Popconfirm title="确认删除?" onConfirm={() => this.deleteRecord(record)}>
                            <Button type="link" size="small">删除</Button>
                        </Popconfirm>
                        <Popconfirm title="确认重置?" onConfirm={() => this.reset(record)}>
                            <Button type="link" size="small">重置密码</Button>
                        </Popconfirm>
                    </Space>
                ),
            },
        ];

        var username = localStorage.getItem("username");
        if (null == username) {
            message.error("请登录！");
        }
        this.state = {
            visible: false,
            addVisible: false,
            data: [],
            pagination: {
                pageNum: 1,
                pageSize: 10,
            },
            loading: false,
            searchText: {
            }
        };
    }

    componentDidMount() {
        const { pagination } = this.state;
        this.fetch({ pagination });
    }

    handleTableChange = (pagination, filters, sorter) => {
        pagination.pageNum = pagination.current;
        this.fetch({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            ...filters,
        });
    }
    modifyRecord(record) {
        this.setState({
            visible: true,
        }, () => {
            this.formRef.current.setFieldsValue({
                ...record
            });
        });

    }

    deleteRecord = (record) => {
        axiosDelete(PathUrl.USER_URL + record.userId, {})
            .then(_r => {
                message.success("删除成功！");
                this.fetch({ pagination: this.state.pagination });
            })
    }

    reset = (record) => {
        axiosPut(PathUrl.USER_PASSWORD_RESET_URL + "/" + record.userId, {})
            .then(_r => {
                message.success("重置成功！");
            })
    }

    handleModifyCancel = () => {
        this.setState({
            visible: false,
        });
        const { pagination } = this.state;
        this.fetch({ pagination });
    }

    fetch = (params = {}) => {
        this.setState({ loading: true });

        let data = {
            sortField: params.sortField,
            sortOrder: params.sortOrder,
            pageNum: params.pagination.pageNum,
            pageSize: params.pagination.pageSize,
            ...this.state.searchText
        };
        axiosGet(PathUrl.USERS_URL, data)
            .then(response => {
                if (response.code === 0) {
                    this.setState({
                        loading: false,
                        data: response.data.list,
                        pagination: {
                            total: response.data.total,
                            pageNum: params.pagination.pageNum,
                            pageSize: params.pagination.pageSize,
                        },
                    });
                } else {
                    message.error(response.msg)
                }
            });
    }

    //search
    searchWorkHours = (values) => {
        this.setState({
            searchText: {
                ...values
            }
        });
        this.fetch({ pagination: this.state.pagination });
    }

    updateInfo = (values) => {
        let data = {
            ...values
        };
        axiosPut(PathUrl.USER_URL + values.userId, data)
            .then(_response => {
                message.success("保存成功！");
                this.fetch({ pagination: this.state.pagination });
                this.setState({
                    visible: false,
                });
            });
    }


    handleAddClick = () => {
        this.setState({
            addVisible: true,
        });
    }
    handleAddCancel = () => {
        this.setState({
            addVisible: false,
        });
    }

    addInfo = (values) => {
        let data = {
            ...values
        };
        axiosPostBody(PathUrl.USER_REGISTER_URL, data)
            .then(_response => {
                message.success("保存成功！");
                this.fetch({ pagination: this.state.pagination });
                this.setState({
                    addVisible: false,
                });
            });
    }

    batchUpload = () => {
        this.props.history.push('/upload-user-file');
    }

    render() {
        const { data, pagination, loading } = this.state;

        return (
            <div>
                <Button
                    type="primary"
                    onClick={() => this.handleAddClick()}
                    style={{ marginBottom: 15, marginLeft: 15 }}
                    icon={<UserAddOutlined />}
                >
                    添加人员
                </Button>
                <Button
                    type="primary"
                    onClick={() => this.batchUpload()}
                    style={{ marginBottom: 15, marginLeft: 15 }}
                    icon={<UsergroupAddOutlined />}
                >
                    批量添加
                </Button>

                <Form
                    name='search'
                    onFinish={(values) => this.searchWorkHours(values)}
                >
                    <Row gutter={[18, 0]} style={{ marginLeft: 7 }}>
                        <Col span={6} xs={24} sm={12} md={12} lg={6} xl={6}>
                            <Form.Item
                                label="用户编号"
                                name="userId"
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={6} xs={24} sm={12} md={12} lg={6} xl={6}>
                            <Form.Item
                                label="用户名"
                                name="username"
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>

                        <Col span={6} xs={24} sm={12} md={12} lg={6} xl={6}>
                            <Form.Item
                                label="人员类型"
                                name="type"
                            >
                                <Select placeholder="请选择" allowClear>
                                    <Option value={UserType.SUPER_ADMIN}>超级管理员</Option>
                                    <Option value={UserType.ADMIN}>项目管理员</Option>
                                    <Option value={UserType.DOCTER}>操作员</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6} xs={24} sm={12} md={12} lg={6} xl={6}>
                            <Form.Item>
                                <Button type="primary" icon={<SearchOutlined />} htmlType="submit">
                                    搜索
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

                <Table
                    columns={this.columns}
                    rowKey={record => record.userId}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={this.handleTableChange}
                    size='middle'
                    scroll={{ x: 1300 }}
                />


                <Modal
                    title="编辑"
                    footer={null}
                    visible={this.state.visible}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleModifyCancel}
                >
                    <Form
                        {...layout}
                        name="modify"
                        onFinish={this.updateInfo}
                        ref={this.formRef}
                    >
                        <Form.Item
                            label="用户编号"
                            name="userId"
                            rules={[{ required: true, message: '请填写!' }]}
                        >
                            <Input disabled />
                        </Form.Item>

                        <Form.Item
                            label="用户名"
                            name="username"
                            rules={[{ required: true, message: '请填写!' }]}
                        >
                            <Input disabled />
                        </Form.Item>

                        <Form.Item
                            label="人员类型"
                            name="type"
                            rules={[{ required: true, message: '请填写!' }]}
                        >
                            <Select placeholder="请选择" allowClear>
                                <Option value={UserType.SUPER_ADMIN}>超级管理员</Option>
                                <Option value={UserType.ADMIN}>项目管理员</Option>
                                <Option value={UserType.DOCTER}>操作员</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="姓名"
                            name="name"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="邮箱"
                            name="email"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="医院"
                            name="hospital"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                                更新
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                    title="添加"
                    footer={null}
                    visible={this.state.addVisible}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleAddCancel}
                >
                    <Form
                        {...layout}
                        onFinish={this.addInfo}
                    >

                        <Form.Item
                            label="用户名"
                            name="username"
                            rules={[{ required: true, message: '请填写!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="人员类型"
                            name="type"
                            rules={[{ required: true, message: '请填写!' }]}
                        >
                            <Select placeholder="请选择" allowClear>
                                <Option value={UserType.SUPER_ADMIN}>超级管理员</Option>
                                <Option value={UserType.ADMIN}>项目管理员</Option>
                                <Option value={UserType.DOCTER}>操作员</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="姓名"
                            name="name"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="邮箱"
                            name="email"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="医院"
                            name="hospital"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                                添加
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}


import React from 'react';
import {
  Form,
  Button,
  Input,
  message,
  Modal,
  InputNumber,
  Radio
} from 'antd';

import * as PathUrl from '@/common/PathUrl'
import { axiosPostBody } from '@/utils/Requests'
import { connect } from 'react-redux';
import { actions } from '@/redux/modules/project'
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 12 },
};

class AddProjectTestee extends React.Component {
  constructor(props) {
    super(props)
    var username = localStorage.getItem("username");
    if (null == username) {
      message.error("请登录！");
    }
    this.state = {
      projectId: 0,
    };
  };

  componentDidMount() {

  }

  static getDerivedStateFromProps(nextProps, preState) {
    if (nextProps.addVisiable !== preState.addVisiable) {
      return {
        ...preState,
        addVisiable: nextProps.addVisiable,
      }
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.addVisiable !== this.state.addVisiable) {
    }
  }

  handleRegectCancel = () => {
    this.props.setAddProjectTesteeVisiable(false);
  }

  save = (values) => {
    let data = {
      ...values,
      projectId: this.props.projectId,
    }
    axiosPostBody(PathUrl.PROJECT_TESTEE_URL, data)
      .then(_r => {
        message.success("保存成功！");
        this.props.refetchProjectTestee(true);
      })
  }

  render() {
    const { addVisible } = this.props;

    return (
      <div>
        <Modal
          title="添加被试人员"
          footer={null}
          visible={addVisible}
          onCancel={this.handleRegectCancel}
        >
          <Form
            {...layout}
            name="addProjectTestee"
            onFinish={(values) => this.save(values)}
          >
            <Form.Item
              label='姓名'
              name="name"
              rules={[{ required: true, message: '请填写!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="性别"
              name="gender"
              rules={[{ required: true, message: '请选择!' }]}
            >
              <Radio.Group>
                <Radio value={0}>未知</Radio>
                <Radio value={1}>男</Radio>
                <Radio value={2}>女</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label='年龄'
              name="age"
              rules={[{ required: true, message: '请填写!' }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              label='电话号码'
              name="phone"
              rules={[{ required: false, message: '请填写!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label='身份证号'
              name="idCard"
              rules={[{ required: false, message: '请填写!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label='备注'
              name="remark"
            >
              <Input />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                保存
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    projectId: state.projectReducer.projectId,
    addVisible: state.projectReducer.addVisible,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setAddProjectTesteeVisiable: (addVisiable) => dispatch(actions.setAddProjectTesteeVisiable(addVisiable)),
    refetchProjectTestee: (isRefechProjectTestee) => dispatch(actions.refetchProjectTestee(isRefechProjectTestee))
  }
}

AddProjectTestee = connect(mapStateToProps, mapDispatchToProps)(AddProjectTestee)

export default AddProjectTestee


import React from 'react';
import {
  Table,
  Form,
  Button,
  Input,
  message,
  Drawer,
  Tag,
  Space,
  DatePicker,
  Descriptions,
  Row,
  Col,
  Select,
  Image,
  InputNumber
} from 'antd';
import {
  SearchOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';

import * as PathUrl from '@/common/PathUrl'
import { axiosGet, axiosPostBody, axiosGetDownload } from '@/utils/Requests'
import { connect } from 'react-redux';
import { actions } from '@/redux/modules/project'
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 5, span: 8 },
};
class ScanItem extends React.Component {

  formRef = React.createRef();
  editFormRef = React.createRef();

  constructor(props) {
    super(props)
    this.columns = [
      {
        title: '检测编号',
        dataIndex: 'scanItemId',
      },
      {
        title: '项目名称',
        dataIndex: 'projectName',
        sorter: false,
      },
      {
        title: '项目期数',
        dataIndex: 'phase',
        sorter: false,
      },
      {
        title: '检测人员',
        dataIndex: 'username',
        sorter: false,
      },
      {
        title: '受试者编号',
        dataIndex: 'entryGroupNum',
      },
      {
        title: '注射日期',
        dataIndex: 'injectionDate',
      },
      {
        title: '随访周期',
        dataIndex: 'followUpPeriod',
      },
      {
        title: 'TST一致',
        dataIndex: 'isSameTst',
        render: (_text, record) => {
          if (record.isSameTst === 1) {
            return (
              <Tag color='green'>
                是
              </Tag>
            );
          } else {
            return (
              <Tag color='volcano'>
                否
              </Tag>
            );
          }
        },
      },
      {
        title: '创建日期',
        dataIndex: 'scanTime',
      },
      {
        title: '手臂类型',
        dataIndex: 'handType',
        render: (_text, record) => {
          if (record.handType === 1) {
            return (
              <Tag color='green'>
                左前臂
              </Tag>
            );
          } else {
            return (
              <Tag color='volcano'>
                右前臂
              </Tag>
            );
          }
        },
      },
      {
        title: '操作',
        key: 'action',
        render: (_text, record) => (
          <Space>
            <Button type="link" size="small" onClick={() => this.view(record)}>查看</Button>
            <Button type="link" size="small" onClick={() => this.edit(record)}>编辑</Button>
          </Space>
        ),
      },

    ];

    var username = localStorage.getItem("username");
    if (null == username) {
      message.error("请登录！");
    }

    let projectName = null;
    let phase = null;
    if (null != this.props.location.query) {
      projectName = this.props.location.query.projectName;
      phase = this.props.location.query.phase;
    }

    this.state = {
      detailsVisible: false,
      data: [],
      originData: {
        pics: []
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
      },
      loading: false,
      searchText: {
        projectName: projectName,
        phase: phase
      },
      record: {},
      picVisible: false,
      editVisible: false,
    };
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  handleTableChange = (pagination, filters, sorter) => {
    pagination.pageNum = pagination.current;
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  }


  detailsDrawerOnClose = () => {
    this.setState({
      detailsVisible: false,
    });
  };


  fetch = (params = {}) => {
    this.setState({ loading: true });
    let data = {
      sortField: params.sortField,
      sortOrder: params.sortOrder,
      pageNum: params.pagination.pageNum,
      pageSize: params.pagination.pageSize,
      ...this.state.searchText
    };
    axiosGet(PathUrl.SCANS_URL, data)
      .then(response => {
        this.setState({
          loading: false,
          data: response.data.list,
          pagination: {
            total: response.data.total,
            pageNum: params.pagination.pageNum,
            pageSize: params.pagination.pageSize,
          },
        });
      });
  }

  //search
  search = (values) => {
    let startDate = null;
    let endDate = null;
    if (null != values.date) {
      startDate = values.date[0].locale('zh-cn').format('YYYY-MM-DD');
      endDate = values.date[1].locale('zh-cn').format('YYYY-MM-DD');
    }
    this.setState({
      searchText: {
        ...values,
        startDate: startDate,
        endDate: endDate
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
      },
    });
    this.fetch({ pagination: this.state.pagination });
  };

  view = (record) => {
    this.setState({
      detailsVisible: true,
      record: record
    });

    axiosGet(PathUrl.SCAN_TESTEE_URL + "/" + record.scanItemId, {})
      .then(response => {
        this.setState({
          originData: response.data,
        });
      });
  }

  setVisible = (vis) => {
    this.setState({
      picVisible: vis,
    })
  }

  editDrawerOnClose = () => {
    this.setState({
      editVisible: false,
    });
  };

  edit = (record) => {

    axiosGet(PathUrl.SCAN_TESTEE_URL + "/" + record.scanItemId, {})
      .then(response => {
        this.setState({
          originData: response.data,
          editVisible: true,
        }, () => {
          let data = {
            ...response.data,
            injectionDate: response.data.injectionDate == null || response.data.injectionDate === "" ? null : moment(response.data.injectionDate),
            photoDate: response.data.injectionDate == null || response.data.injectionDate === "" ? null : moment(response.data.injectionDate),
            isSameTst: response.data.isSameTst == null || response.data.isSameTst === 0 ? 1 : response.data.isSameTst
          }
          this.editFormRef.current.setFieldsValue({
            ...data,
          });
        });
      });
  }

  /**
   * 保存手动录入的受试者测试信息
   * @param {表单值} values 
   */
  saveTestee = (values) => {
    let data = {
      ...values,
      injectionDate: values.injectionDate.locale('zh-cn').format('YYYY-MM-DD HH:mm:ss'),
      photoDate: values.photoDate.locale('zh-cn').format('YYYY-MM-DD HH:mm:ss')
    }
    axiosPostBody(PathUrl.SCAN_TESTEE_URL, data)
      .then(_r => {
        message.success("保存成功！");
        const { pagination } = this.state;
        this.fetch({ pagination });
      })
  }

  exportFile = () => {
    axiosGetDownload(PathUrl.SCANS_EXPORT_URL, {})
      .then(response => {
        const reader = new FileReader()
        reader.readAsDataURL(new Blob([response.data]))
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = "检测数据.xlsx"
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      });

    // window.location.href=PathUrl.SCANS_EXPORT_URL
  }


  render() {
    const { data, pagination, loading, record, originData, picVisible } = this.state;
    const paginationProps = {
      ...pagination,
      showSizeChanger: true,
      showQuickJumper: true,
      pageSize: pagination.pageSize,
      current: pagination.pageNum,
      showTotal: ((total) => {
        return `总共 ${total} 条`;
      }),
    };

    return (
      <div>
        <Drawer
          width={840}
          placement="right"
          closable={true}
          onClose={this.detailsDrawerOnClose}
          visible={this.state.detailsVisible}
        >
          <Descriptions title="详细数据" layout="vertical" bordered column={{ xs: 5, sm: 5, md: 5 }}>
            <Descriptions.Item label="检测编号" span={2}>{record.scanItemId}</Descriptions.Item>
            <Descriptions.Item label="项目名称" span={2}>{record.projectName}</Descriptions.Item>
            <Descriptions.Item label="项目期数" span={1}>{record.phase}</Descriptions.Item>

            <Descriptions.Item label="检测人员" span={2}>{record.username}</Descriptions.Item>
            <Descriptions.Item label="受试者姓名" span={2}>{record.testeeName}</Descriptions.Item>
            <Descriptions.Item label="受试者编号" span={1}>{record.entryGroupNum}</Descriptions.Item>
            {/* <Descriptions.Item label="入组编号(算法识别)" span={2}>{originData.entryGroupNum}</Descriptions.Item>
            <Descriptions.Item label="对比" span={1}>{record.entryGroupNum === originData.entryGroupNum ? <Tag color='green'>相同</Tag> : <Tag color='volcano'>不相同</Tag>}</Descriptions.Item> */}

            <Descriptions.Item label="提交时间" span={2}>{record.scanTime}</Descriptions.Item>

            <Descriptions.Item label="拍照时间" span={2}>{record.photoDate}</Descriptions.Item>
            <Descriptions.Item label="注射时间" span={1}>{record.injectionDate}</Descriptions.Item>
            {/* <Descriptions.Item label="注射日期(算法识别)" span={2}>{originData.injectionDate}</Descriptions.Item>
            <Descriptions.Item label="对比" span={1}>{record.injectionDate === originData.injectionDate ? <Tag color='green'>相同</Tag> : <Tag color='volcano'>不相同</Tag>}</Descriptions.Item> */}

            <Descriptions.Item label="随访周期" span={2}>{record.followUpPeriod}</Descriptions.Item>
            {/* <Descriptions.Item label="随访周期(算法识别)" span={2}>{originData.followUpPeriod}</Descriptions.Item>
            <Descriptions.Item label="对比" span={1}>{record.followUpPeriod === originData.followUpPeriod ? <Tag color='green'>相同</Tag> : <Tag color='volcano'>不相同</Tag>}</Descriptions.Item> */}

            <Descriptions.Item label="药物编号" span={2}>{record.drugNum}</Descriptions.Item>
            {/* <Descriptions.Item label="药物编号(算法识别)" span={2}>{originData.drugNum}</Descriptions.Item>
            <Descriptions.Item label="对比" span={1}>{record.drugNum === originData.drugNum ? <Tag color='green'>相同</Tag> : <Tag color='volcano'>不相同</Tag>}</Descriptions.Item> */}

            <Descriptions.Item label="手臂类型" span={1}>{record.handType === 1 ? <Tag color='green'>左前臂</Tag> : <Tag color='volcano'>右前臂</Tag>}</Descriptions.Item>
            {/* <Descriptions.Item label="手臂类型(算法识别)" span={2}>{originData.handType === 1 ? <Tag color='green'>左前臂</Tag> : <Tag color='volcano'>右前臂</Tag>}</Descriptions.Item>
            <Descriptions.Item label="对比" span={1}>{record.handType === originData.handType ? <Tag color='green'>相同</Tag> : <Tag color='volcano'>不相同</Tag>}</Descriptions.Item> */}


            <Descriptions.Item label="皮肤红晕横径" span={2}>{record.skinBlushHorizontalDiameter}cm</Descriptions.Item>
            {/* <Descriptions.Item label="皮肤红晕横径(算法识别)" span={2}>{originData.skinBlushHorizontalDiameter}cm</Descriptions.Item>
            <Descriptions.Item label="对比" span={1}>{record.skinBlushHorizontalDiameter === originData.skinBlushHorizontalDiameter ? <Tag color='green'>相同</Tag> : <Tag color='volcano'>不相同</Tag>}</Descriptions.Item> */}

            <Descriptions.Item label="皮肤红晕纵径" span={3}>{record.skinBlushVerticalDiameter}cm</Descriptions.Item>
            {/* <Descriptions.Item label="皮肤红晕纵径(算法识别)" span={2}>{originData.skinBlushVerticalDiameter}cm</Descriptions.Item>
            <Descriptions.Item label="对比" span={1}>{record.skinBlushVerticalDiameter === originData.skinBlushVerticalDiameter ? <Tag color='green'>相同</Tag> : <Tag color='volcano'>不相同</Tag>}</Descriptions.Item> */}

            <Descriptions.Item label="皮肤硬结横径" span={2}>{record.skinCallusesHorizontalDiameter}cm</Descriptions.Item>
            {/* <Descriptions.Item label="皮肤硬结横径(算法识别)" span={2}>{originData.skinCallusesHorizontalDiameter}cm</Descriptions.Item>
            <Descriptions.Item label="对比" span={1}>{record.skinCallusesHorizontalDiameter === originData.skinCallusesHorizontalDiameter ? <Tag color='green'>相同</Tag> : <Tag color='volcano'>不相同</Tag>}</Descriptions.Item> */}

            <Descriptions.Item label="皮肤硬结纵径" span={3}>{record.skinCallusesVerticalDiameter}cm</Descriptions.Item>
            {/* <Descriptions.Item label="皮肤硬结纵径(算法识别)" span={2}>{originData.skinCallusesVerticalDiameter}cm</Descriptions.Item>
            <Descriptions.Item label="对比" span={1}>{record.skinCallusesVerticalDiameter === originData.skinCallusesVerticalDiameter ? <Tag color='green'>相同</Tag> : <Tag color='volcano'>不相同</Tag>}</Descriptions.Item> */}

            <Image.PreviewGroup preview={{ picVisible, onVisibleChange: vis => this.setVisible(vis) }}>
              <Row gutter={[24, 0]} style={{ marginLeft: 7 }}>
                {
                  originData.picsWithId && originData.picsWithId.map(item => {
                    return (
                      <Col key={item.id} span={12}>
                        <Image width={320} key={item.id} src={item.url} style={{ marginTop: 10 }} />
                      </Col>
                    )
                  })
                }
              </Row>
            </Image.PreviewGroup>
          </Descriptions>
        </Drawer>
        <Form
          ref={this.formRef}
          name='search'
          onFinish={(values) => this.search(values)}
          initialValues={{ "projectName": this.state.searchText.projectName, "phase": this.state.searchText.phase }}
        >
          <Row gutter={[18, 0]} style={{ marginLeft: 7 }}>
            <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item>
                <Button type="primary" icon={<DownloadOutlined  />} onClick={() => this.exportFile()}>
                  导出
                </Button>
              </Form.Item>
            </Col>


            <Col span={6} xs={24} sm={12} md={12} lg={6} xl={6}>
              <Form.Item
                label="项目名称"
                name="projectName"
              >
                <Input allowClear />
              </Form.Item>
            </Col>

            <Col span={6} xs={24} sm={12} md={12} lg={6} xl={6}>
              <Form.Item
                label="项目期数"
                name="phase"
                rules={[{ required: false, message: '请填写!' }]}
              >
                <Select placeholder="请选择" allowClear>
                  <Option value="一期">一期</Option>
                  <Option value="二期">二期</Option>
                  <Option value="三期">三期</Option>
                  <Option value="四期">四期</Option>
                  <Option value="五期">五期</Option>
                  <Option value="六期">六期</Option>
                  <Option value="七期">七期</Option>
                  <Option value="八期">八期</Option>
                  <Option value="九期">九期</Option>
                  <Option value="十期">十期</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6} xs={24} sm={12} md={12} lg={6} xl={6}>
              <Form.Item
                label="检测编号"
                name="scanNum"
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={6} xs={24} sm={12} md={12} lg={6} xl={6}>
              <Form.Item
                label="检测人员"
                name="username"
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={6} xs={24} sm={12} md={12} lg={6} xl={6}>
              <Form.Item
                label="受试者编号"
                name="entryGroupNum"
              >
                <Input allowClear />
              </Form.Item>
            </Col>

            <Col span={6} xs={24} sm={12} md={12} lg={6} xl={6}>
              <Form.Item
                label="TST一致"
                name="isSameTst"
                rules={[{ required: false, message: '请填写!' }]}
              >
                <Select placeholder="请选择" allowClear>
                  <Option value={1}>是</Option>
                  <Option value={2}>否</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={6} xs={24} sm={12} md={12} lg={6} xl={6}>
              <Form.Item
                label="手臂类型"
                name="handType"
                rules={[{ required: false, message: '请填写!' }]}
              >
                <Select placeholder="请选择" allowClear>
                  <Option value={1}>左前臂</Option>
                  <Option value={2}>右前臂</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={6} xs={24} sm={12} md={12} lg={6} xl={6}>
              <Form.Item
                label="创建日期"
                name="date"
              >
                <RangePicker locale={locale} allowClear />
              </Form.Item>
            </Col>

            <Col span={6} xs={24} sm={12} md={12} lg={6} xl={6}>
              <Form.Item>
                <Button type="primary" icon={<SearchOutlined />} htmlType="submit">
                  搜索
                </Button>
              </Form.Item>
            </Col>
          </Row>

        </Form>

        <Table
          columns={this.columns}
          rowKey={record => record.id}
          dataSource={data}
          pagination={paginationProps}
          loading={loading}
          onChange={this.handleTableChange}
          size='middle'
          scroll={{ x: 1300 }}
        />


        <Drawer
          width={1300}
          placement="right"
          closable={true}
          onClose={this.editDrawerOnClose}
          visible={this.state.editVisible}
        >
          <Row gutter={[24, 0]} style={{ marginLeft: 7 }}>
            <Col span={15} xs={24} sm={15} md={15} lg={15} xl={15}>
              <Image.PreviewGroup preview={{ picVisible, onVisibleChange: vis => this.setVisible(vis) }}>
                <Row gutter={[24, 0]} style={{ marginLeft: 7 }}>
                  {
                    originData.picsWithId && originData.picsWithId.map(item => {
                      return (
                        <Col key={item.id} span={12}>
                          <Image width={320} key={item.id} src={item.url} style={{ marginTop: 10 }} />
                        </Col>
                      )
                    })
                  }
                </Row>
              </Image.PreviewGroup>
            </Col>


            <Col span={16} xs={24} sm={9} md={9} lg={9} xl={9} style={{ position: 'fixed', right: 0, width: '30%', overflow: 'auto', height: '90%' }}>
              <Form
                {...layout}
                onFinish={this.saveTestee}
                ref={this.editFormRef}
              >

                <Form.Item
                  label="记录编号"
                  name="id"
                  rules={[{ required: true, message: '请填写!' }]}
                >
                  <Input disabled />
                </Form.Item>

                <Form.Item
                  label="受试者姓名"
                  name="testeeName"
                  rules={[{ required: true, message: '请填写!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="受试者编号"
                  name="entryGroupNum"
                  rules={[{ required: true, message: '请填写!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="药物编号"
                  name="drugNum"
                  rules={[{ required: true, message: '请填写!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="随访周期"
                  name="followUpPeriod"
                  rules={[{ required: true, message: '请填写!' }]}
                >
                  <Select placeholder="请选择" allowClear>
                    <Option value="0H">0H</Option>
                    <Option value="24H">24H</Option>
                    <Option value="48H">48H</Option>
                    <Option value="72H">72H</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label="注射部位"
                  name="handType"
                  rules={[{ required: true, message: '请填写!' }]}
                >
                  <Select placeholder="请选择" allowClear>
                    <Option value={1}>左前臂</Option>
                    <Option value={2}>右前臂</Option>
                  </Select>
                </Form.Item>


                <Form.Item
                  label="注射日期"
                  name="injectionDate"
                  rules={[{ required: true, message: '请填写!' }]}
                >
                  <DatePicker showTime allowClear style={{ width: '100%' }} format="YYYY-MM-DD HH:mm" />
                </Form.Item>

                <Form.Item
                  label="拍照日期"
                  name="photoDate"
                  rules={[{ required: true, message: '请填写!' }]}
                >
                  <DatePicker showTime allowClear style={{ width: '100%' }} format="YYYY-MM-DD HH:mm" />
                </Form.Item>

                <Form.Item
                  label="红晕横径"
                  name="skinBlushHorizontalDiameter"
                  rules={[{ required: true, message: '请填写!' }]}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item
                  label="红晕纵径"
                  name="skinBlushVerticalDiameter"
                  rules={[{ required: true, message: '请填写!' }]}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item
                  label="硬结横径"
                  name="skinCallusesHorizontalDiameter"
                  rules={[{ required: true, message: '请填写!' }]}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item
                  label="硬结纵径"
                  name="skinCallusesVerticalDiameter"
                  rules={[{ required: true, message: '请填写!' }]}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item
                  label="TST表一致"
                  name="isSameTst"
                  rules={[{ required: true, message: '请填写!' }]}
                  tooltip="是否与TST评估表数据一致"
                >
                  <Select placeholder="请选择" defaultValue={1} allowClear>
                    <Option value={1}>是</Option>
                    <Option value={2}>否</Option>
                  </Select>
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button type="primary" htmlType="submit">
                    保存
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Drawer>
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    projectId: state.projectReducer.projectId,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setProject: (projectId) => dispatch(actions.setProject(projectId)),
  }
}

ScanItem = connect(mapStateToProps, mapDispatchToProps)(ScanItem)

export default ScanItem


import React from 'react';
import {
  Table,
  Form,
  Button,
  Input,
  message,
  Space,
  Row,
  Col,
  Popconfirm,
  Select
} from 'antd';
import {
  SearchOutlined,
  UserAddOutlined
} from '@ant-design/icons';

import * as PathUrl from '@/common/PathUrl'
import { axiosGet, axiosDelete, axiosPostBody } from '@/utils/Requests'
import { connect } from 'react-redux';
import { actions } from '@/redux/modules/project'
import debounce from 'lodash/debounce';

const { Option } = Select;


class ProjectUser extends React.Component {

  formRef = React.createRef();

  constructor(props) {
    super(props)
    this.columns = [
      {
        title: '编号',
        dataIndex: 'id',
        sorter: false,
      },
      {
        title: '用户名',
        dataIndex: 'username',
      },
      {
        title: '姓名',
        dataIndex: 'nickname',
      },
      {
        title: '邮箱',
        dataIndex: 'email',
      },
      {
        title: '创建日期',
        dataIndex: 'createAt',
      },
      {
        title: '操作',
        key: 'action',
        render: (_text, record) => (
          <Space>
            <Popconfirm title="确认删除?" onConfirm={() => this.deleteRecord(record)}>
              <Button type="link" size="small">删除</Button>
            </Popconfirm>
          </Space>
        ),
      },
    ];

    var username = localStorage.getItem("username");
    if (null == username) {
      message.error("请登录！");
    }
    this.state = {
      projectId: 0,
      data: [],
      pagination: {
        pageNum: 1,
        pageSize: 10,
      },
      loading: false,
      searchText: {
      },
      isRefechProjectTestee: false,
      users: [],
    };

    this.searchUser = debounce(this.searchUser, 500);
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  static getDerivedStateFromProps(nextProps, preState) {
    if (nextProps.projectId !== preState.projectId || nextProps.isRefechProjectTestee !== preState.isRefechProjectTestee) {
      return {
        ...preState,
        projectId: nextProps.projectId,
        isRefechProjectTestee: nextProps.isRefechProjectTestee,
      }
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.projectId !== this.state.projectId || prevProps.isRefechProjectTestee !== this.state.isRefechProjectTestee) {
      const { pagination } = this.state;
      this.fetch({ pagination });

      // 重新获取后重置条件
      this.setState({
        isRefechProjectTestee: false,
      })
      this.props.refetchProjectTestee(false);
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    pagination.pageNum = pagination.current;
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  }

  fetch = (params = {}) => {
    this.setState({ loading: true });
    let data = {
      sortField: params.sortField,
      sortOrder: params.sortOrder,
      pageNum: params.pagination.pageNum,
      pageSize: params.pagination.pageSize,
      projectId: this.state.projectId,
      ...this.state.searchText
    };
    axiosGet(PathUrl.PROJECT_USER_URL, data)
      .then(response => {
        this.setState({
          loading: false,
          data: response.data.list,
          pagination: {
            total: response.data.total,
            pageNum: params.pagination.pageNum,
            pageSize: params.pagination.pageSize,
          },
        });
      });
  }

  //search
  search = (values) => {
    let startDate = null;
    let endDate = null;
    if (null != values.date) {
      startDate = values.date[0].locale('zh-cn').format('YYYY-MM-DD');
      endDate = values.date[1].locale('zh-cn').format('YYYY-MM-DD');
    }
    this.setState({
      searchText: {
        ...values,
        startDate: startDate,
        endDate: endDate
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
      },
    });
    this.fetch({ pagination: this.state.pagination });
  };

  deleteRecord = (record) => {
    axiosDelete(PathUrl.PROJECT_USER_URL + "/" + record.id, {})
      .then(_r => {
        message.success("删除成功！");
        this.fetch({ pagination: this.state.pagination });
      })
  }

  setAddProjectTesteeVisiable = () => {
    this.props.setAddProjectTesteeVisiable(true);
  }

  searchUser = (username) => {
    let data = {
      username: username
    }
    axiosGet(PathUrl.USER_SEARCH_URL, data)
      .then(response => {
        console.log(response)
        this.setState({
          users: response.data
        })
      });
  }

  searchChange = () => {
    // this.setState({
    //   users: []
    // });
  }


  addProjectUser = (values) => {
    let projectId = this.state.projectId;
    let userId = values.userId;
    axiosPostBody(PathUrl.PROJECT_USER_URL + "/" + projectId + "/" + userId, {})
      .then(_r => {
        message.success("保存成功！");
        this.fetch({ pagination: this.state.pagination });
      })
  }

  render() {
    const { data, pagination, loading, users } = this.state;
    const paginationProps = {
      ...pagination,
      showSizeChanger: true,
      showQuickJumper: true,
      pageSize: pagination.pageSize,
      current: pagination.pageNum,
      showTotal: ((total) => {
        return `总共 ${total} 条`;
      }),
    };
    return (
      <div>
        <Form
          name='search'
          onFinish={(values) => this.addProjectUser(values)}
        >
          <Row gutter={[18, 0]} style={{ marginLeft: 7 }}>
            <Col span={16}>
              <Form.Item
                label="用户名"
                name="userId"
              >
                <Select
                  placeholder="输入用户名搜索"
                  allowClear
                  // mode='multiple'
                  showSearch
                  onSearch={this.searchUser}
                  onChange={this.searchChange}
                  filterOption={false}
                  notFoundContent={null}
                  showArrow={false}
                  optionLabelProp="label"
                >
                  {
                    users.map(item => {
                      let sub = "";
                      if ("" !== item.name && "" !== item.hospital) {
                        sub = item.name + " / " + item.hospital;
                      }
                      if ("" === item.name) {
                        sub = item.hospital
                      }
                      if ("" === item.hospital) {
                        sub = item.name
                      }
                    
                      return (
                        <Option key={item.userId} value={item.userId} label={item.username}>
                          {item.username} / {item.userId}
                          <p style={{ fontSize: 13, color: 'gray' }}>{sub}</p>
                        </Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item>
                <Button type="primary" icon={<UserAddOutlined />} htmlType="submit">
                  添加
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Form
          name='search'
          onFinish={(values) => this.search(values)}
        >
          <Row gutter={[18, 0]} style={{ marginLeft: 7 }}>
            <Col span={8}>
              <Form.Item
                label="用户名"
                name="username"
              >
                <Input allowClear />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item>
                <Button type="primary" icon={<SearchOutlined />} htmlType="submit">
                  搜索
                </Button>
              </Form.Item>
            </Col>
          </Row>

        </Form>

        <Table
          columns={this.columns}
          rowKey={record => record.id}
          dataSource={data}
          pagination={paginationProps}
          loading={loading}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    projectId: state.projectReducer.projectId,
    isRefechProjectTestee: state.projectReducer.isRefechProjectTestee,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setProject: (projectId) => dispatch(actions.setProject(projectId)),
    setAddProjectTesteeVisiable: (addVisiable) => dispatch(actions.setAddProjectTesteeVisiable(addVisiable)),
    refetchProjectTestee: (isRefechProjectTestee) => dispatch(actions.refetchProjectTestee(isRefechProjectTestee)),
  }
}

ProjectUser = connect(mapStateToProps, mapDispatchToProps)(ProjectUser)

export default ProjectUser

